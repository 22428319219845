import { Inject, Injectable, Optional, Type } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DynamicDialogComponent } from './dynamic-dialog.component';
import { COMPONENT_RESOLVER, DynamicComponentName, IComponentResolver } from './models/component.resolver';
import { DynamicDialogInput } from './models/dialog-input';

@Injectable({ providedIn: 'root' })
export class DynamicDialogService {
  constructor(
    @Inject(COMPONENT_RESOLVER) @Optional() private componentResolver: IComponentResolver | undefined,
    private dialog: MatDialog,
  ) {}

  openDialog(data: MatDialogConfig<DynamicDialogInput>) {
    return this.dialog.open<DynamicDialogComponent, DynamicDialogInput>(DynamicDialogComponent, data);
  }

  async getComponent(cmpName: DynamicComponentName): Promise<Type<unknown> | undefined> {
    return this.componentResolver?.getComponent(cmpName);
  }
}
