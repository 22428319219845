import { Connectable, Observable, using } from 'rxjs';

export function delayedRefCount<T>(delay: number, onUnsubscribe?: () => void) {
  return (source: Observable<T>) => {
    if (!('connect' in source) || typeof source.connect !== 'function') throw new Error('Source is not connectable');
    const connectable = source as Connectable<T>;

    let length = 0;
    let timeout: ReturnType<typeof setTimeout> | null = null;

    const unsubscribe = () => {
      length--;

      if (length !== 0) return;

      timeout = setTimeout(() => {
        connectable.connect().unsubscribe();
        onUnsubscribe?.();
      }, delay);
    };

    const onNewSubscriber = () => {
      length++;

      if (length === 1) connectable.connect();

      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }

      return { unsubscribe };
    };

    return using(onNewSubscriber, () => source);
  };
}
